<template>
  <div v-if="!isPrimaryCertified">
    <Login
      :isCms="false"
      :login="primaryAuthC"
      :viewRegister="true"
      :viewResetPass="true"
      :msg.sync="msg"/>
  </div>
  <div class="secondAuthC" v-else>
    <AuthC :login="secondAuthC"
    :msg.sync="msg"/>
  </div>
</template>

<script>
import api from '@/modules/api';
import Login from '@/components/Login.vue';
import AuthC from '@/components/AuthC.vue';

export default {
  name: 'GasLogin',
  components: {
    Login,
    AuthC,
  },
  data() {
    return {
      msg: '',
      isPrimaryCertified: false,
      mailAddress: null,
    };
  },
  methods: {
    // 1次認証
    async primaryAuthC(inputs) {
      this.$store.dispatch('page/onWaiting');
      this.msg = '';
      const res = await api.login('/api/login', inputs)
        .catch((error) => {
          this.msg = error.response.data.msg;
          return false;
        });
      if (res !== false) {
        if (res.data.isSkipNextAuth) {
          this.goNext(res.data);
          return;
        }
        this.isPrimaryCertified = true;
        this.mailAddress = res.data.mailAddress;
      }
      this.$store.dispatch('page/offWaiting');
    },
    // 2次認証
    secondAuthC(inputs) {
      this.$store.dispatch('page/onWaiting');
      this.msg = '';
      this.$set(inputs, 'mailAddress', this.mailAddress);
      api.login('/api/2stAuth/login', inputs)
        .then((response) => {
          this.goNext(response.data);
        })
        .catch((error) => {
          this.$store.dispatch('page/offWaiting');
          this.msg = error.response.data.msg;
        });
    },
    goNext(data) {
      this.$store.dispatch('auth/login', data);
      if (this.saveUrl) {
        const url = this.saveUrl;
        this.$store.commit('page/setDirectUrl', '');
        this.$router.push({ path: url });
      } else {
        this.$router.push({ name: 'gasTop' });
      }
    },
  },
  computed: {
    saveUrl() {
      return this.$store.state.page.directUrl;
    },
  },
};
</script>
<style scoped>
  .secondAuthC{
    height: 90vh;
    display: flex;
  }
</style>
