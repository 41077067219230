<template>
  <div :class="[
  'login mainWrap',
   viewRegister ? 'long-wrap' : 'short-wrap']">

    <div class="login-wrap p-2">
      <div class="title-wrap mx-auto mb-1 mt-4">
        <div class="inlineBlock title-before"></div>
        <div class="inlineBlock title-text">メールアドレス・パスワードを入力してログインしてください</div>
      </div>
      <div class="form-wrap mx-auto mt-5">
        <div>
          <div class="inlineBlock label-wrap">
            <label class="bold" for="input-default">メールアドレス</label>
          </div>
          <b-form-input v-model="id"
            class="inlineBlock input-wrap mb-3 h-50px"
            size="md"
            :autocomplete="'email'"/>
        </div>
        <div class="mt-2">
          <div class="inlineBlock label-wrap">
            <label class="bold" for="input-default">パスワード</label>
          </div>
          <b-form-input type="password"
          class="inlineBlock input-wrap mb-4 h-50px"
          v-model="password"
          maxlength="255"
          :autocomplete="'current-password'"/>
        </div>
      </div>

      <b-alert
        class="mt-3 mx-auto error-wrap"
        :show="errorMsg!==''"
        variant="warning">{{errorMsg}}</b-alert>

      <div class="btn-wrap mx-auto">
        <div class="inlineBlock forget-wrap">
          <p v-if="viewResetPass" class="mt-4">
            <img class="tri" src="@/assets/svg/tri.svg">
            <b-link
              class="ml-1 forget-text"
              :to="resetPassUrl">パスワードを忘れた方はこちらへ</b-link>
          </p>
        </div>
        <div class="inlineBlock login-btn-wrap">
          <b-button
            class="w-100 boldBtn"
            variant="primary"
            @click="kickLogin">ログイン</b-button>
        </div>
      </div>

      <div class="notice-wrap mx-auto mt-2">
        <p class="mb-1 notice-text">※本画面以降で入力された内容はSSL暗号化により保護されます。</p>
        <p class="mb-1 notice-text">※ログインは認証成功後、12時間有効です。</p>
        <p class="mb-1 notice-text">※一定時間内にパスワードの認証に5回失敗した場合、24時間ログインできなくなります。</p>
      </div>

    </div>

    <div
      v-if="viewRegister"
      class="login-wrap p-2 mt-5">
      <div class="register-title-wrap mx-auto mb-1 mt-4">
        <div class="inlineBlock title-before"></div>
        <div class="inlineBlock title-text">メールアドレス・パスワードをまだ登録されていない方</div>
      </div>

      <div class="register-text-wrap mx-auto mt-4">
        当財団の助成事業へのご応募には、メールアドレスの登録が必要です。
        下記フォームよりメールアドレスとパスワードを登録してください。
      </div>

      <div class="register-btn-wrap mx-auto mt-4 mb-3">
        <b-button
          class="w-100 boldBtn"
          variant="info"
          @click="goInitMail">マイページを作成する</b-button>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginComponent',
  props: {
    isCms: {
      type: Boolean,
      default: false,
    },
    login: {
      type: Function,
    },
    viewRegister: {
      type: Boolean,
      default: false,
    },
    msg: {
      type: String,
      default: '',
    },
    viewResetPass: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: '',
      password: '',
    };
  },
  computed: {
    errorMsg() {
      return this.msg;
    },
    resetPassUrl() {
      if (this.isCms) {
        return '/cms/identify/password/recover';
      }
      return '/identify/password/recover';
    },
  },
  methods: {
    kickLogin() {
      const inputs = {
        login_id: this.id,
        password: this.password,
      };
      this.login(inputs);
    },
    goInitMail() {
      this.$router.push({ name: 'gasRegisterInit' });
    },
  },
  created() {
    // if (this.$route.name === 'cmsLogin') {
    //   this.id = 'akiyama@itup.co.jp';
    // } else {
    //   this.id = 'test_user@itup.co.jp';
    // }
    // this.password = 'admin';
  },
};
</script>

<style scoped>

  .h-50px {
    height: 50px;
  }

  .long-wrap {
    min-height: 800px;
  }
  .short-wrap {
    min-height: 500px;
  }

  .login{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .tri {
    margin-bottom: 2px;
  }

  .boldBtn {
    font-weight: bold;
  }

  .login-wrap {
    width: 880px;
    border: solid 1px #CCC;
  }

  .title-wrap {
    width: 700px;
  }
  .register-title-wrap {
    width: 650px;
  }
  .title-text {
    margin-left: 18px;
    font-weight: bold;
    font-size: 24px;
  }
  .title-before {
    position: absolute;
    width: 14px;
    height: 36px;
    background-color: #122889;
  }

  .form-wrap, .error-wrap, .btn-wrap, .notice-wrap {
    width: 650px;
  }
  .label-wrap {
    width: 150px;
  }
  .input-wrap {
    width: 460px;
  }

  .forget-wrap {
    width: 400px;
  }
  .forget-text {
    color: #707070;
  }
  .login-btn-wrap {
    width: 200px;
  }

  .notice-text {
    font-size: 13px;
  }

  .register-text-wrap {
    width: 530px;
  }

  .register-btn-wrap {
    width: 460px;
  }
</style>
