var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
'login mainWrap',
 _vm.viewRegister ? 'long-wrap' : 'short-wrap']},[_c('div',{staticClass:"login-wrap p-2"},[_vm._m(0),_c('div',{staticClass:"form-wrap mx-auto mt-5"},[_c('div',[_vm._m(1),_c('b-form-input',{staticClass:"inlineBlock input-wrap mb-3 h-50px",attrs:{"size":"md","autocomplete":'email'},model:{value:(_vm.id),callback:function ($$v) {_vm.id=$$v},expression:"id"}})],1),_c('div',{staticClass:"mt-2"},[_vm._m(2),_c('b-form-input',{staticClass:"inlineBlock input-wrap mb-4 h-50px",attrs:{"type":"password","maxlength":"255","autocomplete":'current-password'},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]),_c('b-alert',{staticClass:"mt-3 mx-auto error-wrap",attrs:{"show":_vm.errorMsg!=='',"variant":"warning"}},[_vm._v(_vm._s(_vm.errorMsg))]),_c('div',{staticClass:"btn-wrap mx-auto"},[_c('div',{staticClass:"inlineBlock forget-wrap"},[(_vm.viewResetPass)?_c('p',{staticClass:"mt-4"},[_c('img',{staticClass:"tri",attrs:{"src":require("@/assets/svg/tri.svg")}}),_c('b-link',{staticClass:"ml-1 forget-text",attrs:{"to":_vm.resetPassUrl}},[_vm._v("パスワードを忘れた方はこちらへ")])],1):_vm._e()]),_c('div',{staticClass:"inlineBlock login-btn-wrap"},[_c('b-button',{staticClass:"w-100 boldBtn",attrs:{"variant":"primary"},on:{"click":_vm.kickLogin}},[_vm._v("ログイン")])],1)]),_vm._m(3)],1),(_vm.viewRegister)?_c('div',{staticClass:"login-wrap p-2 mt-5"},[_vm._m(4),_c('div',{staticClass:"register-text-wrap mx-auto mt-4"},[_vm._v(" 当財団の助成事業へのご応募には、メールアドレスの登録が必要です。 下記フォームよりメールアドレスとパスワードを登録してください。 ")]),_c('div',{staticClass:"register-btn-wrap mx-auto mt-4 mb-3"},[_c('b-button',{staticClass:"w-100 boldBtn",attrs:{"variant":"info"},on:{"click":_vm.goInitMail}},[_vm._v("マイページを作成する")])],1)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-wrap mx-auto mb-1 mt-4"},[_c('div',{staticClass:"inlineBlock title-before"}),_c('div',{staticClass:"inlineBlock title-text"},[_vm._v("メールアドレス・パスワードを入力してログインしてください")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inlineBlock label-wrap"},[_c('label',{staticClass:"bold",attrs:{"for":"input-default"}},[_vm._v("メールアドレス")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inlineBlock label-wrap"},[_c('label',{staticClass:"bold",attrs:{"for":"input-default"}},[_vm._v("パスワード")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notice-wrap mx-auto mt-2"},[_c('p',{staticClass:"mb-1 notice-text"},[_vm._v("※本画面以降で入力された内容はSSL暗号化により保護されます。")]),_c('p',{staticClass:"mb-1 notice-text"},[_vm._v("※ログインは認証成功後、12時間有効です。")]),_c('p',{staticClass:"mb-1 notice-text"},[_vm._v("※一定時間内にパスワードの認証に5回失敗した場合、24時間ログインできなくなります。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-title-wrap mx-auto mb-1 mt-4"},[_c('div',{staticClass:"inlineBlock title-before"}),_c('div',{staticClass:"inlineBlock title-text"},[_vm._v("メールアドレス・パスワードをまだ登録されていない方")])])
}]

export { render, staticRenderFns }